import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TWheelData, ILevel, IArea } from "typings/meta-mirror";
import palette from "palette";
import AddWheelAreaSettingsPage from "./AreaSettingsPage";

const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

const StyledScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  position: relative;
  height: 100%;
  min-height: 400px;
  margin-top: 10px;
  align-content: center;
  scroll-behavior: smooth;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: ${palette.font.caption};
  }
`;

const StyledPagesContainer = styled.div`
  position: relative;
  padding-right: 10px;
  min-height: 300px;
`;

const StyledPageControlsContainer = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledPageNumberButton = styled(Button)`
  border-radius: 100% !important;
  height: 50px !important;
  width: 50px !important;
  min-width: 50px !important;
  padding: 0 !important;
  margin: 0 5px !important;
`;

const StyledArrowButton = styled(Button)`
  border-radius: 100% !important;
  border-width: 0px !important;
  height: 50px !important;
  width: 50px !important;
  min-width: 50px !important;
  padding: 0 !important;
  margin: 0 5px !important;
  font-size: 35px !important;
`;

interface IProps {
  areaSettings: TWheelData;
  flushRTEs: boolean;
  updateAreaSettings(area: Partial<IArea>, areaIndex: number): void;
  mirrorLevelType: string;
}

const AddWheelSettings: React.FC<IProps> = ({
  areaSettings,
  updateAreaSettings,
  flushRTEs,
  mirrorLevelType,
}: IProps) => {
  const scrollerRef = useRef<HTMLDivElement>();

  const [visibleAreaIndex, setVisibleAreaIndex] = useState(0);

  useEffect(() => {
    if (areaSettings?.length - 1 < visibleAreaIndex) {
      setVisibleAreaIndex(areaSettings?.length - 1);
    }
  }, [areaSettings?.length]);

  const updateAreaName = (areaName: string, areaIndex: number): void => {
    updateAreaSettings({ areaName }, areaIndex);
  };

  const updateAreaLevels = (levels: ILevel[], areaIndex: number): void => {
    updateAreaSettings({ levels }, areaIndex);
  };

  const handleChangePage = (page: number): void => {
    setVisibleAreaIndex(page);
    setTimeout(() => {
      scrollerRef.current.scrollTo(0, 0);
    }, 500);
  };

  return (
    <StyledContainer>
      <Typography variant="h6">Configure Areas</Typography>
      <StyledScrollContainer ref={scrollerRef}>
        <StyledPagesContainer>
          {areaSettings?.map((area, index) => (
            <AddWheelAreaSettingsPage
              visible={index === visibleAreaIndex}
              key={`area-${index}`}
              areaIndex={index}
              areaName={area.areaName}
              levels={area.levels}
              flushRTEs={flushRTEs}
              updateAreaLevels={updateAreaLevels}
              updateAreaName={updateAreaName}
              mirrorLevelType={mirrorLevelType}
            />
          ))}
        </StyledPagesContainer>
      </StyledScrollContainer>
      <StyledPageControlsContainer>
        <StyledArrowButton
          color="primary"
          variant="outlined"
          onClick={() => handleChangePage(visibleAreaIndex - 1)}
          disabled={visibleAreaIndex === 0}
        >
          <i className="uil uil-angle-left-b"></i>
        </StyledArrowButton>
        {areaSettings?.map((area, areaIndex) => (
          <StyledPageNumberButton
            key={`$area-button-${areaIndex}`}
            color="primary"
            variant={areaIndex === visibleAreaIndex ? "contained" : "outlined"}
            onClick={() => handleChangePage(areaIndex)}
          >
            {areaIndex + 1}
          </StyledPageNumberButton>
        ))}
        <StyledArrowButton
          color="primary"
          variant="outlined"
          onClick={() => handleChangePage(visibleAreaIndex + 1)}
          disabled={visibleAreaIndex === areaSettings?.length - 1}
        >
          <i className="uil uil-angle-right-b"></i>
        </StyledArrowButton>
      </StyledPageControlsContainer>
    </StyledContainer>
  );
};

export default AddWheelSettings;
