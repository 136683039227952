import React from "react";
import { Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import TextInput from "components/common/TextInput/TextInput";
import { ILevel } from "typings/meta-mirror";
import palette from "palette";
import RichTextEditor from "./RichTextEditor";
import { LEVEL_PARTS } from "constants/index";

const StyledContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const StyledLevelHeading = styled(Typography)`
  font-size: 16px !important;
  color: ${palette.font.body} !important;
  font-weight: 700 !important;
  margin-bottom: 5px !important;
`;

interface IProps {
  visible: boolean;
  levels: ILevel[];
  areaName: string;
  areaIndex: number;
  flushRTEs: boolean;
  updateAreaName(areaName: string, areaIndex: number): void;
  updateAreaLevels(levels: ILevel[], areaIndex: number): void;
  mirrorLevelType: string;
}

type UpdateLevel = (
  index: number,
  key: keyof ILevel,
  value: ILevel[keyof ILevel]
) => void;

const AddWheelSettingsPage: React.FC<IProps> = ({
  visible,
  levels,
  areaName,
  updateAreaLevels,
  updateAreaName,
  areaIndex,
  flushRTEs,
  mirrorLevelType,
}: IProps) => {
  const [containerVisible, setContainerVisible] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState(areaName);
  const [areaLevels, setAreaLevels] = React.useState(levels);
  const [levelNames, setLevelNames] = React.useState(
    Array.from(levels, (level) => level?.name ?? "")
  );
  const delayedUpdateAreaName = React.useCallback(
    _.debounce((newAreaName, index) => updateAreaName(newAreaName, index), 500),
    []
  );
  const delayedUpdateAreaLevels = React.useCallback(
    _.debounce(
      (newLevels, index) =>
        updateAreaLevels(
          newLevels.map(({ name, ...rest }: any) => ({
            ...rest,
            name: name?.length ? name : undefined,
          })),
          index
        ),
      500
    ),
    []
  );
  const levelType = React.useMemo(() => mirrorLevelType, [mirrorLevelType]);

  const levelText = React.useMemo(
    () => (mirrorLevelType === "sequential" ? "Level" : "Part"),
    [mirrorLevelType]
  );

  const getLevel = (level: ILevel) =>
    `${levelText} ${
      levelText === "Level" ? `${level.level}` : LEVEL_PARTS[level.level - 1]
    }`;

  const handleUpdateLevel: UpdateLevel = (index, key, value) => {
    const newLevels = _.cloneDeep(areaLevels);
    //  @ts-ignore
    newLevels[index][key] = value;
    setAreaLevels(newLevels);
    delayedUpdateAreaLevels(newLevels, areaIndex);
  };

  React.useEffect(() => {
    areaName !== textInputValue && setTextInputValue(areaName);
    !_.isEqual(levels.sort(), areaLevels.sort()) && setAreaLevels(levels);
  }, [areaName, levels]);

  return (
    <CSSTransition
      in={visible}
      classNames="add-wheel-settings-page"
      timeout={1000}
      unmountOnExit
      mountOnEnter
      onExited={() => setContainerVisible(false)}
      onEnter={() => setContainerVisible(true)}
    >
      <StyledContainer visible={visible === true ? visible : containerVisible}>
        <Grid container direction="column">
          <TextInput
            value={textInputValue}
            label={`Area ${areaIndex + 1} Name`}
            onChange={({ target: { value } }) => {
              setTextInputValue(value);
              delayedUpdateAreaName(value, areaIndex);
            }}
            inputProps={{ maxLength: 40 }}
            fullWidth
          />
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={3}
            className="mt-2"
          >
            {levels.map((level, index) => (
              <Grid key={level.level + levelType} item xs={12} lg={6}>
                <TextInput
                  value={levelNames[index]}
                  label={`${getLevel(level)} Name`}
                  name={`level-${level.level}-name`}
                  onChange={({ target: { value } }) => {
                    setLevelNames((prev) => {
                      const newLevelNames = [...prev];
                      newLevelNames[index] = value;
                      return newLevelNames;
                    });
                    handleUpdateLevel(index, "name", value);
                  }}
                  inputProps={{ maxLength: 40 }}
                  fullWidth
                />
                <StyledLevelHeading variant="body1">
                  {getLevel(level)} Description
                </StyledLevelHeading>

                <RichTextEditor
                  placeholder={`Insert ${getLevel(level)}
                   Description...`}
                  valueIn={level.description}
                  resetValue={flushRTEs}
                  setLevelDescription={(description: string) =>
                    handleUpdateLevel(index, "description", description)
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </StyledContainer>
    </CSSTransition>
  );
};

export default AddWheelSettingsPage;
